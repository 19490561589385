.center {
	display: flex;
	align-items: center;
}

.left {
	display: flex;
	align-items: left;
}

.options-pane-surface {
	height: 100%;
	margin: 10px;
	flex-direction: column;
	justify-content: center;
}

.option-surface {
	background-color: azure;
}

.option-image {
	display: flex;
	max-width: 100%;
	height: auto;
}

.option-text {
	justify-content: center;
	text-align: justify;
}
