.question-selector {
	margin-bottom: -1%;
	display: flex;
}

.coding-navbar {
	display: flex;
	justify-content: space-between;
	background-color: rgb(188,188,188);
}
