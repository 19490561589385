.dp-container {
	position: relative;
	display: inline-block;
}

.edit-icon {
	padding-top: 8px;
	padding-right: 8px;
	position: absolute;
	right: 0;
	top: 0;
	display: none;
	height: 2vw;
	width: 2vw;
	opacity: 100%;
}
