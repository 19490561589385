.search-bar-custom {
	margin-left: 14%;
	width: 60%;
}

.logo {
	display: block;
	max-width: 150px;
	max-height: 95px;
	width: auto;
	height: auto;
}

#basic-navbar-element {
	color: #f1faff;
}
