.main-home-container {
	background-color: white;
}

.exam-list-header {
	margin-bottom: 1rem;
}

.exam-card-surface {
	width: 18rem;
}

.exam-card {
	width: 18rem;
	background-color: #cdd5dc;
}

.exam-card-button {
	background-color: #29b281;
	margin-left: 1rem;
	margin-right: 1rem;
	margin-bottom: 1rem;
}

.exam-card-button:hover {
	background-color: #00a1ff;
}
